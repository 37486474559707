import NextLink from "next/link";
import {
  ButtonPrimaryLink,
  ButtonHeaderLink,
  ButtonMenuLink,
  ButtonCtaLink,
  ButtonCustomHex,
  ButtonGhost
} from "..";
import { useModal, Modal } from "../../modal";
import { TextLink } from "./styles";
import { usePageData } from "../../../lib/page-components/page-data-provider";
import { useRouter } from "next/router";
import { getPageLinkObject } from "../../../lib/utils";
import NextImage from "../next-image/next-image";

const LINK_TYPES_MAP = {
  text: TextLink,
  buttonHeader: ButtonHeaderLink,
  buttonPrimary: ButtonPrimaryLink,
  buttonCta: ButtonCtaLink,
  buttonMenu: ButtonMenuLink,
  buttonHex: ButtonCustomHex,
  buttonGhost: ButtonGhost
};

const LinkModal = ({ type, modal, children }) => {
  const { modals } = usePageData();
  const isFunctionChildren = typeof children === "function";
  const LinkComponent = LINK_TYPES_MAP[type];
  const { triggerProps, modalProps } = useModal(modal);
  const modalData = modals[modal?.id];
  if (!modalData) {
    return null;
  }

  return (
    <>
      {isFunctionChildren ? (
        children({ ...triggerProps, as: "button" })
      ) : (
        <LinkComponent {...triggerProps}>{children}</LinkComponent>
      )}
      <Modal {...modalProps} {...modalData} />
    </>
  );
};

const flatSlug = (slug) => {
  if (!slug) {
    return "";
  }
  if (Array.isArray(slug)) {
    return slug.join();
  }
  return slug;
};

export const Link = ({
  type = "text",
  text,
  icon,
  href = null,
  target,
  page,
  modal,
  children,
  bigText = false,
  noUnderline = false,
  hexCode
}) => {
  const router = useRouter();
  const LinkComponent = LINK_TYPES_MAP[type];
  const linkObject = href || getPageLinkObject(page);
  const isActive =
    typeof href === "string"
      ? false
      : linkObject.pathname === router.pathname &&
      flatSlug(linkObject.query?.slug) === flatSlug(router.query?.slug);
  const iconImage = icon ? <NextImage media={icon} /> : null;
  const linkProps = {
    target,
    isActive,
    tabIndex: isActive ? -1 : 0,
    rel: target === "_blank" ? "noreferrer" : "",
    iconImage,
    bigText,
    noUnderline,
    hexCode
  };
  const isFunctionChildren = typeof children === "function";

  return modal ? (
    <LinkModal type={type} modal={modal}>
      {iconImage}
      {children || text}
    </LinkModal>
  ) : (
    <>
      {isFunctionChildren ? (
        <NextLink href={linkObject} passHref >
          {children({ ...linkProps })}
        </NextLink>
      ) : (
        <NextLink href={linkObject} passHref >
          <LinkComponent {...linkProps}  >
            {iconImage}
            {children || text}
          </LinkComponent>
        </NextLink>
      )}</>

  );
};

export default Link;
