export const getPageLinkObject = (page) => {
  const locale = page?.locale || "es";
  const slug = page?.slug || "";
  const localePrefix = locale === "ca" ? "/ca" : "";
  return {
    pathname: `${localePrefix}/[[...slug]]`,
    query: { slug: slug.split("/") },
  };
};

export const getPageLinkUrl = (page = { locale: "es", slug: "" }) => {
  if (page === null) return "/";

  const localePrefix = page?.locale === "ca" ? "/ca" : "";
  return `${localePrefix}/${page.slug}`;
};

export default getPageLinkObject;
